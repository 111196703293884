<script setup lang="ts">
	import DataTable from "primevue/datatable";
	import type { DataTableFilterMetaData } from "primevue/datatable";
	import Column from "primevue/column";
	import Search from "@/components/Search.vue";
	import { computed, ref } from "vue";
	import { usePortalServicesHttp } from "@/composables/portal-services-api/http-portal-services";
	import { useGetConnectionEvents } from "@/composables/portal-services-api/portal-services";
	import Loading from "@/components/Loading.vue";

	const props = defineProps<{
		taskId: string;
	}>();


	const filters = ref<{ global: DataTableFilterMetaData }>({
		global: {
			value: null,
			matchMode: "contains",
		},
	});
	const portalServicesHttp = usePortalServicesHttp();

	const { data: connections, isLoading: loading } = useGetConnectionEvents(
        portalServicesHttp,
        computed(() => props.taskId),
        computed(() => props.taskId !== "" && props.taskId !== undefined),
    );

</script>

<template>
	<div class="box-border flex min-h-0 flex-col gap-4 p-4">
		<div
			class="customizedPrimeVueTable keyTable flex grid grid-cols-4 flex-col gap-2 gap-4 overflow-y-auto"
		>
			<DataTable
				tableStyle="min-width: 100%; min-height: 100%;"
				paginator
				:rows="5"
				:filters="filters"
				:value="connections"
				dataKey="ConnectionId"
				:loading="loading"
				:globalFilterFields="['ConnectionSource', 'IP', 'StartTime', 'EndTime']"
				class="col-span-4"
		>
			 <template #header>
				<div class="mb-5 flex justify-start">
					<Search :filters-prop="filters" />
				</div>
			</template>
			
                <Column field="ConnectionSource" header="Connection Source" />
                <Column field="IP" header="IP" />
                <Column field="StartTime" header="Start Time">
					<template #body="slotProps">
						{{ new Date(slotProps.data.StartTime).toLocaleString() }}
					</template>
				</Column>
                <Column field="EndTime" header="End Time" >
					<template #body="slotProps">
						{{ slotProps.data.EndTime == "N/A" ? 'N/A':new Date(slotProps.data.EndTime).toLocaleString() }}
					</template>
				</Column>



				<template #empty>No connections found</template>
				<template #loading>
					<Loading typeOfData="ConnectionEvents" />
				</template>
			</DataTable>
		</div>
	</div>
</template>
