import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { computed, type Ref } from "vue";
import type {
    Api,
    Key
} from "../portal-services-api-models";

//connection events

export function useGetConnectionEvents(
    api: Api<unknown>,
    taskId: Ref<string>,
    enabled: Ref<boolean>,
) {
    return useQuery<Key[]>({
        queryKey: ["connections", taskId.value],
        queryFn: async () => {
            const response = await api.portalServices.getConnectionEvents(taskId.value);
            if (response.status !== 200) throw new Error(await response.text());
            return response.data;
        },
        enabled,
    });
}

//key management
export function useGetKeysByEmail(
    api: Api<unknown>,
    email: Ref<string>,
    enabled: Ref<boolean>,
) {
    return useQuery<Key[]>({
        queryKey: ["keys", email.value],
        queryFn: async () => {
            const response = await api.portalServices.getKeysByEmail(email.value);
            if (response.status !== 200) throw new Error(await response.text());
            return response.data;
        },
        enabled,
    });
}


export function useAddKeyMutation(api: Api<unknown>) {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async ({
            email,
            key
        }: {
            email: string;
            key: string;
        }) => {
            var request = {
                email: email,
                publicKey: key
            };
            try {
                return await api.portalServices.addKey(request);
            } catch (e: any) {
                throw e.error as string;
            }
        },
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ["keys"] }),
    });
}

export function useDeleteKeyMutation(api: Api<unknown>) {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async ({
            email,
            key
        }: {
            email: string;
            key: string;
        }) => {
            var request = {
                email: email,
                publicKey: key
            };
            try {
                return await api.portalServices.deleteKey(request);
            } catch (e: any) {
                throw e.error as string;
            }
        },
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ["keys"] }),
    });
}