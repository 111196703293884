<script setup lang="ts">
	import PageHeader from "@/components/Navigation/PageHeader.vue";
	import { onMounted, ref, nextTick, computed } from "vue";
	import ListConnectionDetails from "@/components/RemoteAccess/ListConnectionDetails.vue";
	import { AuditTrailsType } from "@/composables/api-models";
	import CopyButton from "@/components/CopyButton.vue";

	const storedTask = ref<string | null>(null);
	const taskId = ref<string>("");
	const task = ref<AuditTrailsType>();

	onMounted(async () => {
		storedTask.value = localStorage.getItem("task");
		if (storedTask.value) {
			task.value = JSON.parse(storedTask.value);
			// extract the id from the task
			taskId.value = task.value?.RemoteId ?? "";
			taskId.value = taskId.value.split("/")[1];
		} else {
			console.log("No task found");
		}
	});
	const showCommandTooltip = ref(false);
	const handledCommandTooltip = (value: boolean) => {
		showCommandTooltip.value = value;
		setTimeout(() => {
			showCommandTooltip.value = false;
		}, 2000);
	};

	const showNestedCommandTooltip = ref(false);
	const handledNestedCommandTooltip = (value: boolean) => {
		showNestedCommandTooltip.value = value;
		setTimeout(() => {
			showNestedCommandTooltip.value = false;
		}, 2000);
	};

	const isOpen = ref(false);
	const contentHeight = ref(0);
	const accordionContent = ref<HTMLDivElement | null>(null);
	const toggleAccordion = async () => {
		isOpen.value = !isOpen.value;
		await nextTick();
		if (accordionContent.value) {
			contentHeight.value = isOpen.value
				? accordionContent.value.scrollHeight
				: 0;
		}
	};

	const showCopyButton = computed(() => {
		return task.value?.EndTime && task.value?.EndTime == "N/A";
	});

</script>

<template>
	<PageHeader
		title="Detailed Connection"
		:back="true"
	/>
	<div
		v-if="storedTask == null"
		class="flex h-96 items-center justify-center"
	>
		<div class="text-center">
			<h1 class="text-2xl font-bold">No Connection Details Found</h1>
			<p class="text-gray-500">
				No connection details found. Please go back and select a connection to
				view details
			</p>
		</div>
	</div>
	<div v-else>
		<div  class="bg-grey-100 flex items-center justify-start px-8 text-bright-orange">
			<div class="w-full">
				<div class="rounded bg-netural-orange shadow-md">
					<div class="text-center">
						<button
							class="flex w-full items-center justify-between px-4 py-3 text-lg"
							@click="toggleAccordion"
						>
							<span class="font-bold">ECS Task information:</span>
							<svg
								class="-w-5 h-5 transform transition-transform duration-300"
								:class="{ 'rotate-180': isOpen }"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M19 9l-7 7-7-7"
								></path>
							</svg>
						</button>
						<div
							ref="accordionContent"
							class="overflow-hidden transition-all duration-500 ease-in-out"
							:style="{
								maxHeight: isOpen
									? contentHeight + 'px': '0px',
							}"
						>
                            <div class="grid grid-cols-3 gap-2 p-4 text-left">
                                <div class="font-bold">Task ID</div>
                                <div class="col-span-2">{{ taskId }}</div>
                                <div class="font-bold">Project Name</div>
                                <div class="col-span-2">{{ task?.ProjectName }}</div>
                                <div class="font-bold">Machine Name</div>
                                <div class="col-span-2">{{ task?.MachineName }}</div>
                                <div class="font-bold">Machine Id</div>
                                <div class="col-span-2">{{ task?.MachineId }}</div>
                                <div class="font-bold">Public IP</div>
                                <div class="col-span-2">{{ task?.PublicIP }}</div>
                                <div class="font-bold" v-if="showCopyButton">Command</div>
                                <div class="col-span-2" v-if="showCopyButton">
									<div class="flex items-center">
										<CopyButton 
											:copy-text="`ssh -p 2281 jca@${task?.PublicIP}`"
											:display-text="`ssh -p 2281 jca@${task?.PublicIP}`"
											:is-visible-icon="true"
											@show-tooltip="handledCommandTooltip"
										/>
										<div v-if="showCommandTooltip" class="ml-2 text-dark-green">SSH Command Copied!</div>
									</div>

								</div>
								<div class="font-bold" v-if="showCopyButton">Command for <br> nested proxy access:</div>
								<div class="col-span-2" v-if="showCopyButton">
									<div class="flex items-center">
										<CopyButton 
											:copy-text="`ssh -o ProxyCommand='ssh -W %h:%p -p 2281 -o ServerAliveInterval=60 jca@${task?.PublicIP}' -o ServerAliveInterval=60 jca@127.0.0.1 -p 4444 -L0.0.0.0:7722:localhost:22`"
											:display-text="`ssh -o ProxyCommand='ssh -W %h:%p -p 2281 -o ServerAliveInterval=60 jca@${task?.PublicIP}' -o ServerAliveInterval=60 jca@127.0.0.1 -p 4444 -L0.0.0.0:7722:localhost:22`"
											:is-visible-icon="true"
											@show-tooltip="handledNestedCommandTooltip"
										/>
										<div v-if="showNestedCommandTooltip" class="ml-2 text-dark-green">SSH Command Copied!</div>
									</div>
								</div>
                                <div class="font-bold">Start Time</div>
								<div class="col-span-2">{{ task?.StartTime ? new Date(task.StartTime).toLocaleString() : "" }}</div>
                                <div class="font-bold">End Time</div>
                                <div class="col-span-2">{{ task?.EndTime && task?.EndTime!="N/A" ? new Date(task.EndTime).toLocaleString():"N/A"  }}</div>
                            </div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="box-border flex min-h-0 flex-col gap-4 pl-5">
			<ListConnectionDetails :taskId="taskId" />
		</div>
	</div>
</template>
