<script setup lang="ts">
	import { ref, defineProps } from "vue";
	const copied = ref(false);
	const props = defineProps<{
		copyText: string;
		displayText: string;
		isVisibleIcon: boolean;
	}>();

	const emit = defineEmits<{
		showTooltip: [boolean];
		activeText: [string];
	}>();

	const copyToClipboard = () => {
		navigator.clipboard.writeText(props.copyText);
		copied.value = true;
		emit("showTooltip", true);
		emit("activeText", props.displayText);
		setTimeout(() => {
			copied.value = false;
		}, 2000);
	};
</script>
<template>
	<div class="relative inline-block">
		<button
			@click="copyToClipboard()"
			class="custom-button"
		>
			<span class="mr-5 break-normal md:break-all">
				{{ displayText }}
			</span>
			<img
				src="@/assets/activeConnections/copy_icon.svg"
				alt="Copy icon"
				v-if="isVisibleIcon"
				style="display: inline"
			/>
		</button>
	</div>
</template>
